function initDeleteModal() {
  function clearModal() {
    deleteForm.action = '';
    selectedAction.action = '';
    deleteDescription.innerHTML = '';
    deleteModal.close();
  }

  function openDeleteModal(url, id, desc) {
    console.log(url, id);
    deleteForm.action = url;
    selectedAction.value = id;
    deleteDescription.innerHTML = desc;
    deleteModal.showModal();
  }
  document.addEventListener('submit', function (e) {
    if (e.target && e.target.matches('form[data-ajax-form]')) {
      e.preventDefault();
      const form = e.target;
      fetch(
        form.getAttribute('action'), {
          method: 'POST',
          body: new FormData(form),
          headers: {
            'X-CSRFToken': form.querySelector('[name=csrfmiddlewaretoken]').value
          }
        }).then(response => {
        if (response.ok) {
          if (form.dataset.afterSuccess !== undefined) {
            window.location.href = form.dataset.afterSuccess;
          } else {
            window.location.reload();
          }
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    }
  });

  document.querySelectorAll('[data-delete-button]').forEach((el) => {
    el.addEventListener('click', (ev) => {
      console.log(el.dataset)
      openDeleteModal(el.dataset.deleteUrl, el.dataset.deleteId, el.dataset.deleteDesc)
    })
  })
  deleteModalCancel.addEventListener('click', () => {
    clearModal()
  })
}

function initSideNav() {
  document.querySelectorAll('[data-recording-name]').forEach((inp) => {
    if (inp.dataset.originalValue !== '' && inp.dataset.originalValue != inp.value) {
      inp.form.querySelector("[value='save']").classList.remove('hidden')
    } else {
      inp.form.querySelector("[value='save']").classList.add('hidden')
    }
  })
}

function initDebugBar() {
  document.querySelectorAll('[data-debugbar]').forEach((debugbar) => {
    debugbar.querySelectorAll('[data-tab]').forEach((tab) => {
      tab.addEventListener('click', function () {
        debugbar.querySelectorAll('[data-tab]').forEach((t) => {
          t.classList.remove('tab-active');
        });
        tab.classList.add('tab-active');
        debugbar.dataset.debugbarMirror = tab.dataset.tab;
        var source = document.querySelector(`#${tab.dataset.tab}Box`);
        debugbar.querySelector('[data-debugbar-display]').innerHTML = source.innerHTML;
      })
    })
  })
}

function initAttachLinks() {
  const regex = /(#\d+)/g;
  document.querySelectorAll('[data-issue-link]').forEach(el => {
    const link = el.dataset.issueLink;
    if (link.indexOf('{index}') === -1) {
      return;
    }
    const parts = el.textContent.split(regex);

    el.textContent = '';

    parts.forEach(text => {
      var a = document.createTextNode(text);
      if (regex.test(text)) {
        a = document.createElement('a');
        a.href = link.replace('{index}', text.slice(1));
        a.className += 'text-blue-600 hover:underline'
        a.textContent = text;
      }
      el.appendChild(a);
    });

  })
}

function initSearch() {
  console.log('10')
  const bar = document.querySelector('[data-search-bar]')
  bar.addEventListener('input', function () {
    var query = (bar.value || '').toString().toLowerCase()
    if (query.length === 0) {
      if (typeof searchBarStyles !== 'undefined') {
        searchBarStyles.parentNode.removeChild(searchBarStyles);
      }
    } else {
      var sheet = document.createElement('style')
      sheet.innerHTML = `
        [data-search-doc] { display: none; }
        [data-search-doc*='${query}'] { display: flex; }
        `;
      sheet.id = 'searchBarStyles';
      if (typeof searchBarStyles !== 'undefined') {
        searchBarStyles.parentNode.removeChild(searchBarStyles);
      }
      document.body.appendChild(sheet);
    }
  })
}
// init everything
(() => {
  initDeleteModal()
  initSideNav()
  initDebugBar()
  initAttachLinks()
  window.addEventListener("load", () => {
    initSearch();
  });
})()
